exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ascii-index-js": () => import("./../../../src/pages/ascii/index.js" /* webpackChunkName: "component---src-pages-ascii-index-js" */),
  "component---src-pages-games-index-js": () => import("./../../../src/pages/games/index.js" /* webpackChunkName: "component---src-pages-games-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-story-index-jsx": () => import("./../../../src/pages/story/index.jsx" /* webpackChunkName: "component---src-pages-story-index-jsx" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-1942-1942-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/1942/1942.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-1942-1942-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-1943-1943-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/1943/1943.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-1943-1943-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-crashbcoot-crashbcoot-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/crashbcoot/crashbcoot.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-crashbcoot-crashbcoot-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-doom-doom-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/doom/doom.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-doom-doom-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-fifa-2005-fifa-2005-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/fifa2005/fifa2005.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-fifa-2005-fifa-2005-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-galaga-galaga-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/galaga/galaga.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-galaga-galaga-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-gradius-iii-gradius-iii-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/gradiusIII/gradiusIII.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-gradius-iii-gradius-iii-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-mario-64-mario-64-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/mario64/mario64.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-mario-64-mario-64-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-mariokart-64-mariokart-64-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/mariokart64/mariokart64.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-mariokart-64-mariokart-64-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-marioworld-marioworld-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/marioworld/marioworld.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-marioworld-marioworld-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-oddworldoddysee-oddworldoddysee-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/oddworldoddysee/oddworldoddysee.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-oddworldoddysee-oddworldoddysee-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-pacman-25-pacmad-25-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/pacman25/pacmad25.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-pacman-25-pacmad-25-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-streetfighter-2-streetfighter-2-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/streetfighter2/streetfighter2.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-streetfighter-2-streetfighter-2-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-streetfightercollection-streetfightercollection-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/streetfightercollection/streetfightercollection.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-streetfightercollection-streetfightercollection-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-tekken-3-tekken-3-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/tekken3/tekken3.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-tekken-3-tekken-3-md" */),
  "component---src-templates-game-details-js-content-file-path-src-markdown-games-tonyhawkps-tonyhawkps-md": () => import("./../../../src/templates/game-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/games/tonyhawkps/tonyhawkps.md" /* webpackChunkName: "component---src-templates-game-details-js-content-file-path-src-markdown-games-tonyhawkps-tonyhawkps-md" */),
  "component---src-templates-project-details-js-content-file-path-src-markdown-projects-my-website-md": () => import("./../../../src/templates/project-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/projects/my-website.md" /* webpackChunkName: "component---src-templates-project-details-js-content-file-path-src-markdown-projects-my-website-md" */),
  "component---src-templates-project-details-js-content-file-path-src-markdown-projects-rfid-hacking-md": () => import("./../../../src/templates/project-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/projects/rfid-hacking.md" /* webpackChunkName: "component---src-templates-project-details-js-content-file-path-src-markdown-projects-rfid-hacking-md" */),
  "component---src-templates-story-details-js-content-file-path-src-markdown-stories-ai-ai-md": () => import("./../../../src/templates/story-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/stories/ai/ai.md" /* webpackChunkName: "component---src-templates-story-details-js-content-file-path-src-markdown-stories-ai-ai-md" */),
  "component---src-templates-story-details-js-content-file-path-src-markdown-stories-swim-23-swim-23-md": () => import("./../../../src/templates/story-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/stories/swim23/swim23.md" /* webpackChunkName: "component---src-templates-story-details-js-content-file-path-src-markdown-stories-swim-23-swim-23-md" */),
  "component---src-templates-story-details-js-content-file-path-src-markdown-stories-tokyo-tokyo-md": () => import("./../../../src/templates/story-details.js?__contentFilePath=/home/daniel/dev/web/gatsbydemo/kitscratch/src/markdown/stories/tokyo/tokyo.md" /* webpackChunkName: "component---src-templates-story-details-js-content-file-path-src-markdown-stories-tokyo-tokyo-md" */)
}

